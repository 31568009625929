<template>
    <div>
        <vxe-modal v-model="changeDiaVis" title="信息修改" width="60%" height="100%" :before-hide-method='handleClose'
            show-footer show-zoom resize remember>
            <template #default>
                <transition name='fade-transform' mode="out-in">
                    <el-empty description="个人信息加载中,请您耐心等待" v-if="loading"></el-empty>
                    <div v-else>
                        <div class="tip">
                            <!-- 温馨提示：论文评审系统已进行升级，为了更加准确地邀请您评审论文及支付专家评审费，需要您更新部分个人信息。 -->
                            郑重承诺：江苏高擎论文评审中心承诺对于您填写的所有信息将严格保密，并保证信息安全不泄露。
                        </div>
                        <el-divider content-position="left" class="divider"><span style="color:#409EFF;">专家个人信息</span>
                        </el-divider>
                        <vxe-form :data="infoChange" :rules="rules" title-align="right" title-width="100" ref="ruleForm1">
                            <vxe-form-item title="姓名：" field="name" span="12" key="name">
                                <template #default>
                                    <vxe-input v-model="infoChange.name" autocomplete="new-text" placeholder="请输入名称"></vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="单位："  field="university" span="12" key="university">
                                <template #title>
                                    <div>
                                        <!-- <el-radio v-model="infoChange.defaultContactType" :label="0">邮箱：</el-radio> -->
                                        单位
                                        <el-tooltip class="item" effect="dark" content="如需修改，请联系管理员（feedback@deepthinking.net.cn）" placement="top">
                                            <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                                            </el-image>
                                        </el-tooltip>
                                    </div>
                                </template>
                                <template #default>

                                    <!-- <vxe-select v-model="infoChange.university" placeholder="请选择单位">
                                        <vxe-option v-for="(item,index) in universityList" :key="index"
                                            :label="item.universityName" :value="item.universityName"></vxe-option>
                                    </vxe-select> -->
                                    <el-select disabled v-model="infoChange.university" auto-complete="new-text" filterable class="form-line-item" size="small"
                                        placeholder="请选择单位">
                                        <el-option v-for="item in universityList" :label="item.universityName"
                                            :value="item.universityName"></el-option>
                                    </el-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="邮箱：" field="mail" span="12" key="mail">
                                <template #title>
                                    <div>
                                        <!-- <el-radio v-model="infoChange.defaultContactType" :label="0">邮箱：</el-radio> -->
                                        邮箱
                                        <el-tooltip class="item" effect="dark" content="如需修改，请联系管理员（feedback@deepthinking.net.cn）" placement="top">
                                            <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                                            </el-image>
                                        </el-tooltip>
                                    </div>
                                </template>
                                <template #default>
                                    <vxe-input disabled v-model="infoChange.mail" placeholder="请输入邮箱"></vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="手机号:" field="mobile" span="12" key="mobile">
                                <template #title>
                                    <div>
                                        <!-- <el-radio v-model="infoChange.defaultContactType" :label="1">手机：</el-radio> -->
                                        手机
                                        <el-tooltip class="item" effect="dark" content="请填写您的常用手机号，用于税务系统申报个人所得税" placement="top">
                                            <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                                            </el-image>
                                        </el-tooltip>
                                    </div>
                                </template>
                                <template #default>
                                    <vxe-input v-model="infoChange.mobile" autocomplete="new-text" placeholder="请输入手机号"></vxe-input>
                                </template>
                            </vxe-form-item>

                        </vxe-form>
                        <!-- <span style="margin-left:11%;font-size:12px;color:red;">
                                注：请填写邮箱和手机号，并勾选邮件（推荐）或手机号作为您的首选联系方式。
                            </span> -->
                        <el-divider content-position="left" class="divider"><span style="color:#409EFF;">专家学术信息</span>
                        </el-divider>
                        <vxe-form :data="infoChange" :rules="rules" title-align="right" title-width="100" ref="ruleForm2">
                            <vxe-form-item title="职称：" field="title" span="12" key="title">
                                <template #default>
                                    <vxe-select v-model="infoChange.title" placeholder="请选择专家职称" class="form-line-item">
                                        <vxe-option label="正高级" value="1"></vxe-option>
                                        <vxe-option label="副高级" value="0"></vxe-option>
                                        <vxe-option label="中级" value="2"></vxe-option>
                                        <vxe-option label="其他" value="3"></vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="导师类型：" field="type" span="12" key="type">
                                <template #default>
                                    <vxe-select v-model="infoChange.type" placeholder="请选择导师类型">
                                        <vxe-option label="博士生导师" value="1"></vxe-option>
                                        <vxe-option label="硕士生导师" value="0"></vxe-option>
                                        <vxe-option label="其他" value="2"></vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="研究方向：" field="expert_theme" span="12" key="expert_theme">
                                <template #default>
                                    <vxe-input v-model="infoChange.expert_theme" placeholder="请输入研究方向"></vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="人才称号：" field="honors" span="12" key="honors">
                                <template #default>
                                    <!-- <vxe-select v-model="infoChange.honors" filterable placeholder="选填"
                                        class="form-line-item">
                                        <vxe-option label="院士" value="院士"></vxe-option>
                                        <vxe-option label="千人" value="千人"></vxe-option>
                                        <vxe-option label="长江" value="长江"></vxe-option>
                                        <vxe-option label="杰青" value="杰青"></vxe-option>
                                        <vxe-option label="四青" value="四青"></vxe-option>
                                    </vxe-select> -->
                                    <el-select v-model="infoChange.honors" filterable multiple allow-create
                                        placeholder="选填，可在下拉框中勾选，也可直接填写" class="form-line-item" size="small">
                                        <!-- <el-option label="院士" value="院士"></el-option>
                                        <el-option label="千人" value="千人"></el-option>
                                        <el-option label="长江" value="长江"></el-option>
                                        <el-option label="杰青" value="杰青"></el-option>
                                        <el-option label="四青" value="四青"></el-option> -->
                                        <el-option v-for="item in honorList" :label="item" :value="item" :key="item"></el-option>
                                    </el-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="关键词:" field="keywords" span="24" key="keywords">
                                <template #title>
                                    <span>
                                        关键词
                                        <el-tooltip class="item" effect="dark"
                                            content="请至少填写5个学术关键词。您还可以通过+号录入更多的关键词，以便更加精准地邀请您审稿。" placement="top">
                                            <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                                            </el-image>
                                        </el-tooltip>
                                    </span>
                                </template>
                                <template #default>
                                    <div class="keywordOut">
                                        <div v-for="(item,index) in keywords" class="keywords" :key="'key' + index">
                                            <el-input v-model="keywords[index]"  size="medium"
                                                class='inputChange1'>
                                            </el-input>
                                        </div>
                                        
                                    </div>
                                    <i class="el-icon-circle-plus" style="color:#409EFF;" @click="insertHandle"></i>
                                </template>
                            </vxe-form-item>
                        </vxe-form>
                        <el-divider content-position="left" class="divider"><span style="color:#409EFF;">专家学科信息</span>
                        </el-divider>
                        <vxe-form :data="infoChange" :rules="rules" title-align="right" title-width="100" ref="ruleForm4">
                            <vxe-form-item title="您是否可评审学术型研究生论文：" field="available" title-align="left" title-width="280" span="24">
                                <template #title>
                                    <div style="padding-left: 30px;">
                                        您是否可评审学术型研究生论文：
                                    </div>
                                </template>
                                <template #default>
                                    <el-radio-group v-model="infoChange.available">
                                        <el-radio label="是">是</el-radio>
                                        <el-radio label="否">否</el-radio>
                                    </el-radio-group>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="评审学术型研究生学科：" field="major" v-if="infoChange.available=='是'" span="24" title-width="225">
                                <template #default>
                                        <el-cascader v-for="(major,index) in infoChange.major" :key="major[0] + major[1] + major[2]" 
                                            v-model="infoChange.major[index]" :options="majorList1" popper-class="changeInfo-cas"
                                            :props="{ expandTrigger: 'hover',value:'value',label:'label'}"
                                            :show-all-levels='false' filterable  size="small" clearable
                                            placeholder="请选择学科" style="width: 200px;margin-right: 5px;"></el-cascader>
                                            <el-button type="text" @click="insertMajor(infoChange.major)">添加</el-button>
                                        <!-- <i class="el-icon-circle-plus" style="color:#409EFF;" @click="insertMajor(infoChange.major)"></i> -->
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="您是否可评审专业学位研究生论文：" field="availableMaster" title-align="left" title-width="280" span="24">
                                <template #title>
                                    <div style="padding-left: 30px;">
                                        您是否可评审专业学位研究生论文：
                                    </div>
                                </template>
                                <template #default>
                                    <el-radio-group v-model="infoChange.availableMaster">
                                        <el-radio label="是">是</el-radio>
                                        <el-radio label="否">否</el-radio>
                                    </el-radio-group>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="评审专业学位研究生学科：" field="customMajorMaster" v-if="infoChange.availableMaster=='是'" title-width="240" span="24">
                                <template #default>
                                    <el-cascader v-for="(major,index) in infoChange.customMajorMaster" :key="major[0] + major[1] + major[2]" 
                                            v-model="infoChange.customMajorMaster[index]" :options="majorList2" popper-class="changeInfo-cas"
                                            :props="{ expandTrigger: 'hover',value:'value',label:'label'}"
                                            :show-all-levels='false' filterable  size="small" clearable
                                            placeholder="请选择学科" style="width: 200px;margin-right: 5px;"></el-cascader>
                                            <el-button type="text" @click="insertMajor(infoChange.customMajorMaster)">添加</el-button>
                                        <!-- <i class="el-icon-circle-plus" style="color:#409EFF;" @click="insertMajor(infoChange.customMajorMaster)"></i> -->
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="您是否可评审本科毕业设计（论文）：" field="availableUndergraduate" title-align="left" title-width="280" span="24">
                                <template #title>
                                    <div style="padding-left: 30px;">
                                        您是否可评审本科毕业设计（论文）：
                                    </div>
                                </template>
                                <template #default>
                                    <el-radio-group v-model="infoChange.availableUndergraduate">
                                        <el-radio label="是">是</el-radio>
                                        <el-radio label="否">否</el-radio>
                                    </el-radio-group>
                                    <div style="display: inline-block;margin-left: 20px;color:red">
                                        注：本科毕业设计（论文）一般评审周期较短（5天左右）
                                    </div>
                                </template>
                            </vxe-form-item>
                            <!-- <vxe-form-item title-align="left" span="14">
                                <template #default>
                                    <div style="color:red">
                                        注：本科毕业设计（论文）一般评审周期较短（5天左右）
                                    </div>
                                </template>
                            </vxe-form-item> -->
                            <vxe-form-item title="评审本科专业：" field="customMajorUndergraduate" v-if="infoChange.availableUndergraduate=='是'" span="24" title-width="170">
                                <template #default>
                                    <el-cascader v-for="(major,index) in infoChange.customMajorUndergraduate" :key="major[0] + major[1] + major[2]" 
                                            v-model="infoChange.customMajorUndergraduate[index]" :options="majorList3" popper-class="changeInfo-cas"
                                            :props="{ expandTrigger: 'hover',value:'value',label:'label'}"
                                            :show-all-levels='false' filterable  size="small" clearable
                                            placeholder="请选择学科" style="width: 200px;margin-right: 5px;"></el-cascader>
                                        <el-button type="text" @click="insertMajor(infoChange.customMajorUndergraduate)">添加</el-button>
                                        <!-- <i class="el-icon-circle-plus" style="color:#409EFF;" @click="insertMajor(infoChange.customMajorUndergraduate)"></i> -->
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="近三年是否指导过本科毕业设计：" v-if="infoChange.availableUndergraduate=='是'" key="conduct" field="conduct" title-width="282" span="24">
                                <template #default>
                                    <el-radio-group v-model="infoChange.conduct">
                                        <el-radio label="是">是</el-radio>
                                        <el-radio label="否">否</el-radio>
                                    </el-radio-group>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="是否指导过优秀本科毕业设计：" v-if="infoChange.availableUndergraduate=='是'" key="conductType" field="conductType" title-width="282" span="24">
                                <template #default>
                                    <el-checkbox-group v-model="infoChange.conductType">
                                        <el-checkbox label="国家级">国家级</el-checkbox>
                                        <el-checkbox label="省级">省级</el-checkbox>
                                        <el-checkbox label="校级">校级</el-checkbox>
                                    </el-checkbox-group>
                                </template>
                            </vxe-form-item>
                        </vxe-form>
                        <el-divider content-position="left" class="divider"><span style="color:#409EFF;">评审费支付信息</span>
                        </el-divider>
                        <vxe-form :data="infoChange" :rules="rules" title-align="right" title-width="100" ref="ruleForm3">
                            <vxe-form-item title="支付方式：" field="defaultPayType" span="24" key="defaultPayType">
                                <template #default>
                                    <el-radio-group v-model="payType" size="small">
                                        <!-- <el-radio :label="0" border>支付宝</el-radio>
                                        <el-radio :label="1" border>微信</el-radio> -->
                                        <el-radio :label="2" border>银行卡</el-radio>
                                    </el-radio-group>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="开户人：" field="bankName" span="12" key="bankName">
                                <template #title>
                                    <span>
                                        开户人
                                        <el-tooltip class="item" effect="dark" content="需要使用您本人银行卡，“开户人”信息与“姓名”信息一致" placement="top">
                                            <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                                            </el-image>
                                        </el-tooltip>
                                    </span>
                                </template>
                                <template #default>
                                    <vxe-input  v-model="infoChange.name" placeholder="请输入开户人姓名" disabled></vxe-input>

                                </template>

                            </vxe-form-item>
                            <vxe-form-item span="12" >
<!--                                占位用-->
                            </vxe-form-item>
                            <vxe-form-item title="身份证号:" field="IDNum" span="12" key="IDNum">
                                <template #title>
                                    <span>
                                        <el-select v-model="idvalue" placeholder="请选择" size="small" class="select">
                                            <el-option
                                            label="身份证号"
                                            value="身份证号">
                                            </el-option>
                                            <el-option
                                            label="护照号"
                                            value="护照号">
                                            </el-option>
                                        </el-select>
                                        <!-- <el-tooltip class="item" effect="dark" content="用于支付评审费及在税务系统申报个人所得税" placement="top">
                                            <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                                            </el-image>
                                        </el-tooltip> -->
                                    </span>
                                </template>
                                <template #default>
                                    <vxe-input v-model="infoChange.IDNum" placeholder="请输入开户人身份证号"></vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item span="12" >
                                <div style="display: inline-block;margin-left: 20px;color:red">
                                    注：请确保证件号码准确无误，以免影响您的个人所得税申报
                                </div>
                            </vxe-form-item>
                            <!-- <vxe-form-item title="支付宝号:" field="alipayId" key="alipay1" span="24"
                                v-if='infoChange.defaultPayType == 0'>
                                <template #default>
                                    <vxe-input v-model="infoChange.alipayId"  placeholder="请输入支付宝号"></vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item title="微信账号:" field="wechatId" key="wechat1" span="24"
                                v-if='infoChange.defaultPayType == 1'>
                                <template #default v-if='infoChange.defaultPayType == 1'>
                                    <vxe-input v-model="infoChange.wechatId"  placeholder="请输入微信号"></vxe-input>
                                </template>
                            </vxe-form-item> -->
                            <vxe-form-item title="银行卡号:" field="bankCardNum" key="bankCardNum" span="12" v-if='payType == 2'>
                                <template #title>
                                    <span>
                                        银行卡号
                                        <el-tooltip class="item" effect="dark" content="用于支付评审费，请确保银行卡号准确，且不要包含空格等。"
                                            placement="top">
                                            <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                                            </el-image>
                                        </el-tooltip>
                                    </span>
                                </template>
                                <template #default>
                                    <vxe-input v-model="infoChange.bankCardNum"  placeholder="请输入银行卡号">
                                    </vxe-input>
                                </template>
                            </vxe-form-item>
                        </vxe-form>
                    </div>
                </transition>
                
            </template>
            <template #footer>
                <vxe-button @click="cancelHandle" size="medium">取 消</vxe-button>
                <vxe-button status="primary" @click="handleSaveInfo">
                    保 存</vxe-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
    import toolNull from '@/tools/null'
    export default {
        name: 'changeInfo',
        data() {
            return {
                //对话框
                idvalue:"身份证号",
                formLabelWidth: '25%',
                honorList:['中国科学院院士','中国工程院院士','院士（外国）','长江学者','国家杰出青年科学基金','新世纪优秀人才支持计划','千人计划','万人计划','国家四青人才','省级人才称号'],
                infoChange: {
                    name: '',
                    university: '',
                    mobile: '',
                    mail: '',
                    title: '',
                    type: '',
                    major: '',
                    expert_theme: '',
                    honors: [],
                    keywords: [],
                    bankCardNum: '',
                    bank: '',
                    IDNum: '',
                    url: '',
                    alipayId: '',
                    wechatId: '',
                    defaultPayType: 0,
                    bankName: '',
                },
                //图片列表
                imgVis: false,
                //一级学科
                majorList1: [],
                majorList2: [],
                majorList3: [],
                //单位
                universityList: [],
                //关键词
                keywords: ['', '', '', '', ''],
                changeDiaVis: false,
                //校验使用
                // rules1: {
                //     name: [{
                //         validator: checkName,
                //         trigger: 'blur'
                //     }],
                //     university: [{
                //         validator: checkuUniversity,
                //         trigger: 'blur'
                //     }],
                // },
                rules: {
                    name: [{
                        validator({
                            itemValue
                        }) {
                            if (!itemValue) {
                                return new Error('请输入姓名');
                            }
                        }
                    }],
                    university: [{
                        validator({
                            itemValue
                        }) {
                            if (!itemValue) {
                                return new Error('请输入单位');
                            }
                        }
                    }],
                    mobile: [{
                            validator({
                                itemValue
                            }) {
                                if (!itemValue) {
                                    return new Error('请输入手机号');
                                }
                            },
                        },
                        {
                            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
                            message: '请输入正确手机号',
                            trigger: 'blur'
                        }
                    ],
                    mail: [{
                            validator({
                                itemValue
                            }) {
                                if (!itemValue) {
                                    return new Error('请输入邮箱');
                                }
                            },
                        },
                        {
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: '请输入正确邮箱',
                            trigger: 'blur'
                        }
                    ],
                    title: [{
                        validator({
                            itemValue
                        }) {
                            if (!itemValue) {
                                return new Error('请选择职称');
                            }
                        }
                    }],
                    type: [{
                        validator({
                            itemValue
                        }) {
                            if (!itemValue) {
                                return new Error('请选择导师类型');
                            }
                        }
                    }],
                    // major: [{
                    //     validator({
                    //         itemValue
                    //     }) {
                    //         if (!itemValue) {
                    //             return new Error('请选择二级学科');
                    //         }
                    //     }
                    // }],
                    expert_theme: [{
                        validator({
                            itemValue
                        }) {
                            if (itemValue.length < 4 || itemValue.length > 50) {
                                return new Error('研究方向字数限制为4 - 50字');
                            }
                        }
                    }],
                    keywords: [{
                        validator: ({
                            itemValue
                        }) => {
                            let count = 0;
                            this.keywords.forEach(item => {
                                if (item != '') count += 1;
                            });
                            if (count < 5) {
                                return new Error('至少需要输入5个关键词');
                            }
                        }
                    }],
                    bankCardNum: [{
                            validator({
                                itemValue
                            }) {
                                if (!itemValue) {
                                    return new Error('请输入银行卡号');
                                }
                            }
                        },
                        {
                            pattern: /^[1-9]\d{9,29}$/,
                            message: '请输入正确银行卡号',
                            trigger: 'blur'
                        }
                    ],
                    IDNum: [{
                            validator:({
                                itemValue
                            })=>{
                                if (!itemValue) {
                                    return new Error('请输入' + this.idvalue);
                                }
                                let reg1 = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
                                if(this.idvalue == '身份证号' && !reg1.test(itemValue)){
                                    return new Error('请输入正确身份证号');
                                }
                                let reg2 = /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([Rr][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)||(^[A-Za-z]{2}\d{6}$)/
                                if(this.idvalue == '护照号' && !reg2.test(itemValue)){
                                    console.log(itemValue)
                                    return new Error('请输入正确护照号');
                                }
                            }
                        },
                        // {
                        //     pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
                        //     message: '请输入正确身份证号',
                        //     trigger: 'blur'
                        // }
                    ],
                    // url: [{
                    //     validator: check,
                    //     trigger: 'blur'
                    // }],
                    alipayId: [{
                        validator({
                            itemValue
                        }) {
                            if (!itemValue) {
                                return new Error('请输入支付宝号');
                            }
                        }
                    }],
                    wechatId: [{
                            validator({
                                itemValue
                            }) {
                                if (!itemValue) {
                                    return new Error('请输入微信号');
                                }
                            }
                        },
                        {
                            pattern: /^[a-zA-Z][-_a-zA-Z0-9]{5,19}$/,
                            message: '请输入正确微信号',
                            trigger: 'blur'
                        }
                    ],
                    defaultPayType: [{
                        validator({
                            itemValue
                        }) {
                            if (itemValue != 0 && itemValue != 1 && itemValue != 2) {
                                return new Error('请选择支付方式');
                            }
                        }
                    }],
                    available:[{
                            validator:({
                                itemValue
                            })=>{
                                if(this.infoChange.available == "否" && this.infoChange.availableMaster=='否' && this.infoChange.availableUndergraduate=='否'){
                                    return new Error("至少选择一个可评审的学位类型")
                                }
                            },
                        }
                    ],
                    availableMaster:[{
                            validator:({
                                itemValue
                            })=>{
                                if(this.infoChange.available == "否" && this.infoChange.availableMaster=='否' && this.infoChange.availableUndergraduate=='否'){
                                    return new Error("至少选择一个可评审的学位类型")
                                }
                            }
                        }
                    ],
                    availableUndergraduate:[{
                            validator:({
                                itemValue
                            })=>{
                                if(this.infoChange.available == "否" && this.infoChange.availableMaster=='否' && this.infoChange.availableUndergraduate=='否'){
                                    return new Error("至少选择一个可评审的学位类型")
                                }
                            }
                        }
                    ],
                    major:[
                        {
                            validator:({
                                itemValue
                            })=>{
                                let count = 0;
                                this.infoChange.major.forEach(item=>{
                                    if(item && Array.isArray(item) && item.length > 0) count ++;
                                })
                                if(this.infoChange.available == '是' && count == 0){
                                    return new Error("至少选择一个学科")
                                }
                            }
                        }
                    ],
                    customMajorMaster:[
                        {
                            validator:({
                                itemValue
                            })=>{
                                let count = 0;
                                this.infoChange.customMajorMaster.forEach(item=>{
                                    if(item && Array.isArray(item) && item.length > 0) count ++;
                                })
                                if(this.infoChange.availableMaster == '是' && count == 0){
                                    return new Error("至少选择一个学科")
                                }
                            }
                        }
                    ],
                    customMajorUndergraduate:[
                        {
                            validator:({
                                itemValue
                            })=>{
                                let count = 0;
                                this.infoChange.customMajorUndergraduate.forEach(item=>{
                                    if(item && Array.isArray(item) && item.length > 0) count ++;
                                })
                                if(this.infoChange.availableUndergraduate == '是' && count == 0){
                                    return new Error("至少选择一个学科")
                                }
                            }
                        }
                    ]
                    // bankName: [{
                    //     validator({
                    //         itemValue
                    //     }) {
                    //         if (!itemValue) {
                    //             return new Error('请输入开户人姓名');
                    //         }
                    //     }
                    // }]
                },
                payType: 2,
                notify:'',
                checkList:[],
                loading:true
            }
        },
        props: ['expertId'],
        created() {
            //获取一级学科
            this.getMajor();
            //获取院校信息
            this.getUniversityList();
            //获取专家信息
            this.get_expert_info();
            this.changeDiaVis = this.$store.state.professor.isChangeDiaVis;
        },
        watch: {
            '$store.state.professor.isChangeDiaVis': function () {
                this.changeDiaVis = this.$store.state.professor.isChangeDiaVis;
                if (this.changeDiaVis) {
                    this.notify = this.$notify({
                        title: '温馨提示',
                        message: '论文评审系统已进行升级，为了更加准确地邀请您评审研究生学位论文及本科毕业设计（论文），需要您更新。',
                        duration: 0,
                        type: 'warning',
                        offset: 30
                    });
                }
                else
                {
                    this.notify.close();
                }
            }
        },
        methods: {
            //获取院校列表
            getUniversityList() {
                this.$api.info.getUniversity()
                    .then(res => {
                        this.universityList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            //获取专家信息
            get_expert_info() {
                this.$api.info.getExpert().then(res => {
                    if (res.data.code == 200) {
                        this.infoChange = {
                            name: toolNull.null2Empty(res.data.data.name),
                            university: toolNull.null2Empty(res.data.data.university),
                            mobile: toolNull.null2Empty(res.data.data.mobile),
                            mail: toolNull.null2Empty(res.data.data.mail),
                            title: toolNull.null2Empty(res.data.data.title),
                            type: toolNull.null2Empty(res.data.data.type),
                            expert_theme: toolNull.null2Empty(res.data.data.expert_theme),
                            available:res.data.data.available == null ? '是':res.data.data.available,
                            availableMaster:res.data.data.availableMaster == null ? '否':res.data.data.availableMaster,
                            availableUndergraduate:res.data.data.availableUndergraduate == null ? '否':res.data.data.availableUndergraduate,
                            major: res.data.data.customMajor == null ? [[]] : res.data.data.customMajor.split(
                                '%').map(item=>item.split(',')),
                            customMajorMaster: res.data.data.customMajorMaster == null ? [[]] : res.data.data.customMajorMaster.split(
                            '%').map(item=>item.split(',')),
                            customMajorUndergraduate: res.data.data.customMajorUndergraduate == null ? [[]] : res.data.data.customMajorUndergraduate.split(
                            '%').map(item=>item.split(',')),
                            conduct:res.data.data.conduct == null?'是':res.data.data.conduct,
                            conductType:res.data.data.conductType == null?[]:res.data.data.conductType.split('、'),
                            honors: res.data.data.honors == null || res.data.data.honors == '' ? [] : res
                                .data.data.honors
                                .split(','),
                            keywords: toolNull.null2Empty(res.data.data.customKeywords),
                            bankCardNum: toolNull.null2Empty(res.data.data.bankCardNum),
                            bank: toolNull.null2Empty(res.data.data.bank),
                            bankName: toolNull.null2Empty(res.data.data.bankName),
                            IDNum: toolNull.null2Empty(res.data.data.idnum),
                            url: toolNull.null2Empty(res.data.data.url),
                            alipayId: toolNull.null2Empty(res.data.data.alipayId),
                            wechatId: toolNull.null2Empty(res.data.data.wechatId),
                            defaultPayType: toolNull.null2Empty(res.data.data.defaultPayType),
                            defaultContactType: toolNull.null2Empty(res.data.data.defaultContactType)
                        };
                        //匹配身份证号
                        let reg = /^[1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dXx]$/;
                        console.log(reg.test(this.infoChange.IDNum));
                        // let reg = /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([Rr][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)||(^[A-Za-z]{2}\d{6}$)/;
                        if(reg.test(this.infoChange.IDNum)){
                            // this.idvalue = '护照号'
                            this.idvalue = '身份证号'
                        }else{
                            // this.idvalue = "身份证号";
                            this.idvalue = "护照号";
                            }
                        if (this.infoChange.keywords != '') {
                            let arr = this.infoChange.keywords.split(',');
                            for (let i = arr.length; i < 5; i++) arr.push('');
                            this.keywords = arr;
                        } else {
                            this.keywords = ['', '', '', '', ''];
                        }
                        this.loading = false;
                    } else {
                        this.$message.warning("信息获取失败！");
                    }
                }).catch(err => {
                    this.$message.warning("服务器维护！111");
                })
            },
            //添加关键字
            insertHandle() {
                for (let i = 0; i < 5; i++) this.keywords.push('');
            },
            // 添加学科
            insertMajor(major){
                major.push([]);
            },
            //获取一级学科
           
            getMajor() {
                function changeMajor(marjors){
                    if(marjors == null) return;
                    for(let i = 0;i < marjors.length;i ++){
                        let label =  marjors[i].children?marjors[i].label:`${marjors[i].label}（${marjors[i].value}）`;
                        let value = marjors[i].label
                        marjors[i].label = label;
                        marjors[i].value = value;
                        changeMajor(marjors[i].children);
                    }
                }
                this.$api.info.getAllSubject()
                    .then(res => {
                        this.majorList1 = res.data.data[0];
                        this.majorList2 = res.data.data[1];
                        this.majorList3 = res.data.data[2];
                        changeMajor(this.majorList1);
                        changeMajor(this.majorList2);
                        changeMajor(this.majorList3);
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            async handleSaveInfo() {
                let flag = true;
                await this.$refs['ruleForm1'].validate((callback) => {
                    if (callback) {
                        flag = false;
                        return false;
                    }
                });
                await this.$refs['ruleForm2'].validate((callback) => {
                    if (callback) {
                        flag = false;
                        return false;
                    }
                });
                await this.$refs['ruleForm3'].validate((callback) => {
                    if (callback) {
                        flag = false;
                        return false;
                    }
                });
                await this.$refs['ruleForm4'].validate((callback) => {
                    if (callback) {
                        flag = false;
                        return false;
                    }
                });
                // this.$refs['ruleForm5'].validate((valid) => {
                //     if (!valid) {
                //         flag = false;
                //         return false;
                //     }
                // });
                // this.$refs['ruleForm6'].validate((valid) => {
                //     if (!valid) {
                //         flag = false;
                //         return false;
                //     }
                // });
                if (!flag) return;
                let loading = this.$loading({
                    lock: true,
                    text: '正在保存',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let arr = [];
                this.keywords.forEach(item => {
                    if (item != '') arr.push(item);
                });
                let customMajorMaster = this.infoChange.customMajorMaster.filter(item=>item.length > 0);
                let major = this.infoChange.major.filter(item=>item.length > 0);
                let customMajorUndergraduate = this.infoChange.customMajorUndergraduate.filter(item=>item.length > 0);
                let param = {
                    // expertId: this.expertId,
                    name: this.infoChange.name,
                    university: this.infoChange.university,
                    mobile: this.infoChange.mobile,
                    mail: this.infoChange.mail,
                    title: this.infoChange.title,
                    type: this.infoChange.type,
                    major: major.map(item=>item.join(',')).join('%'),
                    expert_theme: this.infoChange.expert_theme,
                    keywords: arr.join(','),
                    honors: this.infoChange.honors.join(','),
                    defaultPayType: this.infoChange.defaultPayType,
                    alipayId: this.infoChange.alipayId,
                    wechatId: this.infoChange.wechatId,
                    bankCardNum: this.infoChange.bankCardNum,
                    idNum: this.infoChange.IDNum,
                    bank: this.infoChange.bank,
                    bankName: this.infoChange.name,
                    url: '',
                    defaultContactType: this.infoChange.defaultContactType,
                    available:this.infoChange.available,
                    availableMaster:this.infoChange.availableMaster,
                    availableUndergraduate:this.infoChange.availableUndergraduate,
                    customMajorMaster: customMajorMaster.map(item=>item.join(',')).join('%'),
                    customMajorUndergraduate: customMajorUndergraduate.map(item=>item.join(',')).join('%'),
                    conduct:this.infoChange.conduct,
                    conductType:this.infoChange.conductType.join('、'),
                };
                this.$api.info.postCorrectInfo(param)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.changeDiaVis = false;
                            this.$store.dispatch('changeIsChangeDiaVis', false);
                            this.get_expert_info(this.expertId);
                            loading.close();
                            this.$message.success('更新成功！');
                        } else {
                            this.$message.warning('更新失败！');
                            loading.close();
                        }
                    })
                    .catch(err => {
                        this.$message.warning("2服务器维护！");
                        loading.close();
                    })
            },
            //上传图片
            handlePictureCardPreview(file) {
                this.imgVis = true;
                this.infoChange.url = file.url;
            },
            handleClose() {
                this.$store.dispatch('professor/changeIsChangeDiaVis', false);
            },
            cancelHandle() {
                this.changeDiaVis = false;
                this.$store.dispatch('professor/changeIsChangeDiaVis', false);
            }
        },
        components: {

        }
    }
</script>

<style scoped>
    .desc {
        margin-top: 15px;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .divider {
        background-color: #409EFF;
    }

    .diaContent {
        margin-right: 50px;
    }

    .keywordOut{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .keywords {
        width: 19%;
        /* float: left; */
        margin-bottom: 5px;
    }

    .form-line-item {
        width: 100%;
    }

    .wh {
        border-radius: 5px;
        background-color: red;
        width: 20px;
        height: 20px;
    }

    .tip {
        color: red;
        ;
    }
</style>

<style>
    .dialog .el-dialog {
        margin-top: 5vh !important;
        border-radius: 15px;
    }

    .dialog .el-dialog__body {
        padding-top: 10px;
    }

    .inputChange1 .el-input__inner {
        font-size: 12px;
        /* padding: 0px; */
    }

    .set_content_width .el-form-item__content {
        width: 75%;
    }

    .changeInfo-cas .el-cascader-menu__wrap{
        height: 500px !important;
    }

    .select .el-input__suffix{
        right: 0px !important;
    }
    .select .el-input__inner{
        padding-right: 0px !important;
    }

    /* .diaContent .el-form-item {
        margin-bottom: 5px;
    } */

    /* .keywordLabel .el-form-item__label {
        line-height: 20px;
    } */
</style>